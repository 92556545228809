.multipleslider {
  width: 80%;
  margin: auto;
  margin-top: 5rem;
}

.shopbygender {
  margin-top: 3rem;
  text-align: center;
}

.shopbygender h1 {
  margin-bottom: 1rem;
}

.shopbygender a {
  text-decoration: none;
}

.gendercardhead img {
  width: 15rem;
  height: 15rem;
  object-fit: cover;
  background-color: #e0d9d5;
}

.gendercardbody p {
  font-size: 14px;
  font-weight: 600;
  color: black;
}

.shopbygender-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.shopbygender-flex {
  width: 80%;
  margin: auto;
}

.trendingProducts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.trendingProducts {
  margin-top: 5rem;
  text-align: center;
}

.trendingProducts h1 {
  margin-bottom: 1rem;
}

.trendinghead img {
  width: 18rem;
  height: 25rem;
  object-fit: contain;
}

.trendingbody span {
  font-size: 12px;
  font-weight: 100;
}

.homeVideo {
  width: 80%;
  margin: auto;
  position: relative;
}

.homeVideo video {
  width: 100%;
  margin-top: 5rem;
}

.homeVideo img {
  position: absolute;
  padding: 1rem;
  right: 10px;
  bottom: 10px;
  width: calc(1.8rem + 5vw);
  background-color: white;
  border-radius: 1rem;
}

.topSelling {
  margin-top: 5rem;
  text-align: center;
}

.shopByCategory {
  margin-top: 5rem;
  text-align: center;
}

.category {
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  margin-top: 1rem;
  gap: 1rem;
}

.itemname h3 {
  text-align: center;
}

.itemCategory {
  display: flex;
  gap: 2rem;
  /* border: 1px solid #d5b5a2; */
  padding: 0.5rem;
  justify-content: center;
}

.itemImg img {
  width: 10rem;
  height: 12rem;
  object-fit: cover;
  background-color: #e0d9d5;
}

.categoryBtn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

@media only screen and (max-width: 630px) {
  .category {
    grid-template-columns: 1fr;
  }
  .itemCategory {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .itemImg img {
    width: 100%;
    height: auto;
  }
  .categoryBtn * {
    margin: 0.5rem 0;
  }
  .homeVideo {
    width: 100%;
  }
}
