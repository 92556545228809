* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Futura";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Futura";
  src: url("../src/Fonts/Linotype\ -\ FuturaLTPro-Book.otf") format("opentype");
}
