.productCard {
  width: 16rem;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}

.productCardHead {
  height: 13rem;
}

.productCardHead img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.productCardbody {
  margin-top: 0.5rem;
}

.productCard:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  transition: 400ms;
}
