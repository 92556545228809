.hero-slide {
  width: 100%;
  height: 80vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

.hero-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
