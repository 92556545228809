.topselling:nth-child(1) {
  width: 80%;
  margin: auto;
  margin-top: 2rem;
}

.topsellingcard img {
  width: 10rem;
  height: 20rem;
  object-fit: cover;
  transition: 1s;
  background-color: #e0d9d5;
}

.topsellingcard p {
  margin: 0.5rem 0 1rem 0;
}

.swiper-pagination-bullets {
  bottom: 0px !important;
}

.swiper-pagination-bullet-active {
  background-color: #000 !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: black;
}
