.filter {
  left: 1rem;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}

.filterSlider {
  margin: 1rem 0;
}

.filterSliderValue {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
}

.filterLabel {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  margin: 0 0 1rem 0;
  cursor: pointer;
}

.filterFlexbox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  margin: 0.2rem 0;
}
